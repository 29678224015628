import React from "react"
import Img from "gatsby-image"

export default ({ images }) => {
  const image = targetName => images.find(({ name }) => name === targetName)
  const imageFluid = targetName => image(targetName).childImageSharp.fluid

  return (
    <div className="container hero-mobile-images">
      <div className="hero-mobile-images-col -first">
        <Img
          fluid={imageFluid("building")}
          className="hero-image"
          alt="Building with #weremember sign"
        />
        <Img
          fluid={imageFluid("woman2")}
          className="hero-image"
          alt="Woman holding #weremember sign"
        />
        <Img
          fluid={imageFluid("billboard")}
          className="hero-image"
          alt="Billboard with #weremember sign"
        />

        <Img
          fluid={imageFluid("woman1")}
          className="hero-image"
          alt="Woman holding #weremember sign"
        />
      </div>
      <div className="hero-mobile-images-col -second">
        <Img
          fluid={imageFluid("buildings")}
          className="hero-image"
          alt="Many buildings with #weremember sign"
        />
        <Img
          fluid={imageFluid("woman4")}
          className="hero-image"
          alt="Woman holding #weremember sign"
        />
        <Img
          fluid={imageFluid("team2")}
          className="xl:origin-top md:origin-top-right hero-image"
          alt="Players from a football team holding #weremember sign"
        />
        <Img
          fluid={imageFluid("pope")}
          className="md:origin-right xl:origin-center hero-image"
          alt="Pope holding #weremember sign"
        />
      </div>
      <div className="hero-mobile-images-col -third">
        <Img
          fluid={imageFluid("football-team")}
          className="md:origin-right xl:origin-center hero-image"
          alt="Players holding #weremember sign"
        />
        <Img
          fluid={imageFluid("man-cut")}
          className="origin-right hero-image"
          alt="Man holding #weremember sign next to Unesco flag"
        />
        <Img
          fluid={imageFluid("tower")}
          className="origin-right hero-image"
          alt="Tower with #weremember sign"
        />
      </div>
    </div>
  )
}
