import Items from "./items"
import React from "react"
import wjc from "../../images/header/wjc.png"
import { slide as Menu } from 'react-burger-menu'

export default function Header() {
  return (
    <header className="header has-dark-side">
      <div className="dark-side"></div>

      <div className="container dark-side-content header-content">
        <div className="header-logos">
          <img src={wjc} alt="World Jewish Congress" className="wjc" />
        </div>

        <nav className="header-navigation">
          <Items />
        </nav>

        <div className="block md:hidden">
          <Menu right width="100%">
            <Items />
          </Menu>
        </div>
      </div>
    </header>
  )
}
