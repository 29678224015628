import React, { Fragment } from "react"

export default () => {
  return (
    <Fragment>
      <a
        href="https://aboutholocaust.org/en/about"
        target="_blank"
        rel="nofollow noreferrer"
      >
        About
      </a>
      <a
        href="https://aboutholocaust.org/en/facts"
        target="_blank"
        rel="nofollow noreferrer"
      >
        Facts
      </a>
      <a
        href="https://aboutholocaust.org/en/testimonies"
        target="_blank"
        rel="nofollow noreferrer"
      >
        Testimonies
      </a>
    </Fragment>
  )
}
