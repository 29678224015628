import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MobileImages from "./mobile-images"
import Slider from "./hero-slider"
import weRememberLogo from "../../images/hero/weremember-logo.svg"

export default function Hero() {
  const {
    allFile: { nodes: images },
  } = useStaticQuery(graphql`
    query HeroImages {
      allFile(filter: { relativeDirectory: { eq: "hero" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 290) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return (
    <div>

      <div className="hero has-dark-side">
        <div className="dark-side"></div>
        <MobileImages images={images} />
        <div className="dark-side-anti-space">
          <div className="gradient-slider"></div>
          <Slider images={images} />
        </div>
        <div className="container dark-side-content">
          <div className="hero-header">
            <img
              src={weRememberLogo}
              alt="We remember logo"
              className="we-remember-logo"
            />
            <h1>Learn From The Past. Protect The Future.</h1>
            <h2>International Holocaust Remembrance Day, 27 January</h2>
          </div>
        </div>
      </div>
    </div>
  )
}
