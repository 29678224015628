import React, { useState } from "react"
import Cookies from "js-cookie"

const COOKIE_NAME = "cookies-notice-dismissed"
const COOKIE_LIFETIME = 356 // 1 year

export default () => {
  const [visible, setVisible] = useState(!Cookies.get(COOKIE_NAME))
  const dismiss = () => {
    setVisible(false)
    Cookies.set(COOKIE_NAME, "dismissed", { expires: COOKIE_LIFETIME })
  }

  if (!visible) return (null)

  return (
    <div className="cookies-notice">
      <div className="container">
        <div className="cookies-notice-inner">
          <p>
            Cookies allow us to understand how you use this site and improve
            your experience. By continuing to use this website you accept our
            use of cookies.
          </p>
          <button type="button" onClick={() => dismiss()}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
